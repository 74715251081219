import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Student, StudentType } from "../../types/slices/studentType";

const initialState: StudentType = {
    status: ApiStatus.FETCHED,
    students: [],
    errorMessage: undefined,
    editStudent: {} as Student,
    selectedStudent: {} as Student,
    isEdit: false,
    openModal: false
};

const studentSlice = createSlice({
    name: "student",
    initialState,
    reducers: {
        onFetchStudent(state, { payload }: { payload: Student[] }) {
            state.status = ApiStatus.FETCHED;
            state.students = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditStudent(state, { payload }: { payload: Student }) {
            state.editStudent = payload;
        },
        onSetSelectedStudent(state, { payload }: { payload: Student }) {
            state.selectedStudent = payload;
        },
        onSetOpenModal(state, {payload}:{payload:boolean}){
            state.openModal = payload;
        },
        onChangeIsEdit(state, {payload}:{payload:boolean}){
            state.isEdit = payload;
        }
    },
});

export const selectStudentState = (state: RootState) => state.studentSlice;
export default studentSlice.reducer;

export const {
    onFetchStudent,
    changeStatus,
    onSetEditStudent,
    onSetSelectedStudent,
    onSetOpenModal,
    onChangeIsEdit
} = studentSlice.actions;
