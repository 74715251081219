import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Cycle, CycleType, Teacher } from "../../types/slices/cycleType";
import { Course } from "../../types/slices/coursesType";
import { Student } from "../../types/slices/studentType";

const initialState: CycleType = {
    status: ApiStatus.FETCHED,
    statusStudents: ApiStatus.FETCHED,
    cycles: [],
    errorMessage: undefined,
    editCycle: {} as Cycle,
    selectedCycle: {} as Cycle,
    selectedCourse: {} as Course,
    teachers: [],
    selectedTeacher: {} as Teacher,
    studentByCycle: [],
    studentsNotInCycle: [],
    selectedStudentsNotInCycle: [],
    isEdit: false,
    openModal: false
};

const cycleSlice = createSlice({
    name: "cycle",
    initialState,
    reducers: {
        onFetchCycle(state, { payload }: { payload: Cycle[] }) {
            state.status = ApiStatus.FETCHED;
            state.cycles = payload;
            state.errorMessage = undefined;
        },
        onFetchStudentByCycle(state, { payload }: { payload: Student[] }) {
            state.status = ApiStatus.FETCHED;
            state.studentByCycle = payload;
            state.errorMessage = undefined;
        },
        onFetchStudentNotInCycle(state, { payload }: { payload: Student[] }) {
            state.status = ApiStatus.FETCHED;
            state.studentsNotInCycle = payload;
            state.errorMessage = undefined;
        },
        onFetchCourse(state, { payload }: { payload: Course }) {
            state.status = ApiStatus.FETCHED;
            state.selectedCourse = payload;
            state.errorMessage = undefined;
        },
        onFetchTeachers(state, { payload }: { payload: Teacher[] }) {
            state.status = ApiStatus.FETCHED;
            state.teachers = payload;
            state.errorMessage = undefined;
        },
        changeStatus(state, { payload }: { payload: ApiStatus }) {
            state.status = payload;
        },
        onSetEditcycle(state, { payload }: { payload: Cycle }) {
            state.editCycle = payload;
        },
        onSetSelectedCycle(state, { payload }: { payload: Cycle }) {
            state.selectedCycle = payload;
        },
        onSetSelectedTeacher(state, { payload }: { payload: Teacher }) {
            state.selectedTeacher = payload;
        },
        onSetSelectedStudentsNotInCycle(state, { payload }: { payload: Student[] }) {
            state.selectedStudentsNotInCycle = payload;
        },
        onSetOpenModal(state, {payload}:{payload:boolean}){
            state.openModal = payload;
        },
        onChangeIsEdit(state, {payload}:{payload:boolean}){
            state.isEdit = payload;
        }
    },
});

export const selectCycleState = (state: RootState) => state.cycleSlice;
export default cycleSlice.reducer;

export const {
    onFetchCycle,
    changeStatus,
    onFetchStudentByCycle,
    onFetchStudentNotInCycle,
    onFetchCourse,
    onFetchTeachers,
    onSetOpenModal,
    onSetEditcycle,
    onSetSelectedCycle,
    onChangeIsEdit,
    onSetSelectedTeacher,
    onSetSelectedStudentsNotInCycle
} = cycleSlice.actions;
