import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { Course, coursesType, Module, Theme } from "../../types/slices/coursesType";

const initialState: coursesType = {
    statusCourses: ApiStatus.FETCHED,
    statusModules: ApiStatus.FETCHED,
    statusThemes: ApiStatus.FETCHED,
    courses: [],
    errorMessage: undefined,
    editCourse: {} as Course,
    selectedCourse: {} as Course,
    modules: [],
    editModule: {} as Module,
    selectedModule: {} as Module,
    themes: [],
    editTheme: {} as Theme,
    selectedTheme: {} as Theme,
    isEdit: false,
    openModal: false
};

const courseSlice = createSlice({
    name: "course",
    initialState,
    reducers: {
        onFetchCourse(state, { payload }: { payload: Course[] }) {
            state.statusCourses = ApiStatus.FETCHED;
            state.courses = payload;
            state.errorMessage = undefined;
        },
        onFetchModules(state, { payload }: { payload: Module[] }) {
            state.statusModules = ApiStatus.FETCHED;
            state.modules = payload;
            state.errorMessage = undefined;
        },
        onFetchTheme(state, { payload }: { payload: Theme[] }) {
            state.statusThemes = ApiStatus.FETCHED;
            state.themes = payload;
            state.errorMessage = undefined;
        },
        changeStatusCourses(state, { payload }: { payload: ApiStatus }) {
            state.statusCourses = payload;
        },
        changeStatusModules(state, { payload }: { payload: ApiStatus }) {
            state.statusModules = payload;
        },
        changeStatusThemes(state, { payload }: { payload: ApiStatus }) {
            state.statusThemes = payload;
        },
        onSetEditCourse(state, { payload }: { payload: Course }) {
            state.editCourse = payload;
        },
        onSetSelectedCourse(state, { payload }: { payload: Course }) {
            state.selectedCourse = payload;
        },
        onSetEditModule(state, { payload }: { payload: Module }) {
            state.editModule = payload;
        },
        onSetSelectedModule(state, { payload }: { payload: Module }) {
            state.selectedModule = payload;
        },
        onSetEditTheme(state, { payload }: { payload: Theme }) {
            state.editTheme = payload;
        },
        onSetSelectedTheme(state, { payload }: { payload: Theme }) {
            state.selectedTheme = payload;
        },
        onSetOpenModal(state, {payload}:{payload:boolean}){
            state.openModal = payload;
        },
        onChangeIsEdit(state, {payload}:{payload:boolean}){
            state.isEdit = payload;
        }
    },
});

export const selectCourseState = (state: RootState) => state.courseSlice;
export default courseSlice.reducer;

export const {
    onFetchCourse,
    onFetchModules,
    onFetchTheme,
    changeStatusCourses,
    changeStatusModules,
    changeStatusThemes,
    onSetEditCourse,
    onSetSelectedCourse,
    onSetEditModule,
    onSetSelectedModule,
    onSetEditTheme,
    onSetSelectedTheme,
    onSetOpenModal,
    onChangeIsEdit
} = courseSlice.actions;
