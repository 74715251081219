import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from './redux/store'


const themeAvenir = createTheme({
  typography: {
     fontFamily: 'Poppins, sans-serif !important',
     fontWeightBold: 'bolder'
   },
  components: {
   MuiInputBase:{
     styleOverrides: {
       root: {
         fontFamily: 'Source Sans Pro !important',
       },
     },
   },
   MuiListItemText:{
     styleOverrides: {
       root: {
         fontFamily: 'Poppins, sans-serif !important',
       },
     },
   },
  MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: 'Source Sans Pro !important',
        },
      },
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeAvenir}>
      <CssBaseline />
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
