import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuthStore } from "./hooks/useAuthStore";
import { AuthStatus } from "./types/slices/authType";
import { Backdrop, CircularProgress } from "@mui/material";
import { RoutesMap } from "./types/common/routes";
import { HomeView } from "./views/Home/HomeView";
import { RestrictedRoute } from "./routes";
import { Protected } from "./components/Layout/Protected";
import { useSelector } from "./redux/store";
import { CustomBackdrop } from "./components/common/CustomBackdrop/CustomBackdrop";
import "./App.css"; //no comentar
import { Toaster } from "react-hot-toast";
import { Certificate } from "crypto";

function App() {
    /* hooks */
    const { verifySession, status, user } = useAuthStore();
    const location = useLocation();

    const { alertType, message, show } = useSelector(
        (state) => state.snackbarSlice
    );
    const { showBackdrop } = useSelector((state) => state.backdropSlice);

    useEffect(() => {
        verifySession();
    }, []);

    if (status === AuthStatus.VERIFYING) {
        return (
            <Backdrop open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <>
            <Routes>
                {/* RUTAS PUBLICAS */}
                {/* <Route
                    path="/"
                    element={<Navigate to={RoutesMap.MAIN} replace />}
                /> */}

                {/* RUTAS PRIVADAS */}
                {status === AuthStatus.NOT_AUTHENTICATED ? (
                    <>
                        <Route path="/:type" element={<HomeView />} />
                        <Route
                            path="/*"
                            element={<Navigate to={"/:type"} replace />}
                        />
                    </>
                ) : (
                    <>
                        <Route path="/" element={<Protected />}>
                        </Route>
                    </>
                )}
            </Routes>
            <CustomBackdrop open={showBackdrop || false} />
            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    style: {
                        background: "#c9c7c7",
                        color: "#fff",
                    },
                }}
            />
        </>
    );
}

export default App;
